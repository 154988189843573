.login-container {
  width: 480px;
  margin: 0 auto 64px;
  padding: 164px 0 32px;
  text-align: center;

  h2 {
    margin-bottom: 32px;
  }
}
