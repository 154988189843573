.login-container {
  width: 320px;
  margin: 0 auto 64px;
  padding: 164px 0 32px;

  h2 {
    text-align: center;
    margin-bottom: 32px;
  }
}
