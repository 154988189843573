.project-table {
  th {
    border: none;
  }
}
.menu-item-table {
  th {
    border: none;
  }
}
