.breakWordAll {
  word-wrap: break-word;
  word-break: break-all;
}

.config-link {
  display: inline-block;
  height: 16px;
  width: 16px;
  background: url('images/ico-gear-white.svg') 50% 50% transparent;
  background-size: contain;
  vertical-align: text-bottom;
}
